export const environment = {
    production: true,
    SPOKE_URL: 'https://uat.test.thehuub.io',
    GTW_URL: 'https://gtw.uat.thehuub.co',
    URL_3PL: 'https://api.3pl.uat.thehuub.io',
    URL_CORE_API: 'https://core.uat.thehuub.io',
    SWT_TOKEN: '7fd515c03cc410891ceba31d668acc48c632db806e183a092ebe83f6ab6a83896c8b879f955840e0f0bd914a14c9b75dd43cf3a041b6d7823b320a39ba40ec54',
    IS_ECL: true,
    ECL_PLATFORM_URL: 'https://ecl-platform.uat.maersk.com'
};
