import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// Services
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthguardGuard  {
    constructor(private _user: UserService) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this._user.validateToken();
    }
}
