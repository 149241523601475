import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { reference, internal_order_num } = element;

            const filterList = [reference, internal_order_num];

            return filterListByQuery(filterList, query);
        });
    }
}
