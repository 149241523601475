export class Helpers {
    /**
     * Check if value is unique
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    isValueUnique(list, id): boolean {
        const duplicated = list.filter((elem) => elem.id === id);

        return duplicated.length === 0;
    }

    /**
     * Remove one element from the provided list
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    removeValueFromList(list, id): Array<any> {
        return list.filter((elem) => elem.id !== id);
    }

    /**
     * add element to the provided list
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    addToList(list, elem): Array<any> {
        list.push(elem);
        return list;
    }

    /**
     * Select all elements on a provided list. Putting either checked or selected to True.
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    selectAll(list): Array<any> {
        list.map((elem) => {
            const { checked, selected } = elem;

            if (checked) {
                elem.checked = true;
            }
            if (selected) {
                elem.selected = true;
            }
        });
        return list;
    }

    /**
     * Deselect all elements on a provided list. Putting either checked or selected to False.
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    deselectAll(list): Array<any> {
        list.map((elem) => {
            const { checked, selected } = elem;

            if (checked) {
                elem.checked = false;
            }
            if (selected) {
                elem.selected = false;
            }
        });
        return list;
    }

    /**
     * Fix rendering problem for ng2-select component.
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    fixRendering(list): Array<any> {
        return list.filter((elem) => elem !== null);
    }

    /**
     * Generate a beautiful Date.
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    formatDate(days_to_add = 0): string {
        const curDate = new Date();
        return new Date(curDate.setDate(curDate.getDate() + days_to_add)).toISOString().split('T')[0];
    }

    /**
     * Generate a beautiful Date.
     * @author Tiago Silva <tfsilva@thehuub.co>
     */
    beautifyDate(datetime): string {
        return new Date(datetime).toISOString();
    }

    loadBarUpdate(n_pages, load_bar): boolean {
        // if n_pages is not a number or is less than 1 -> = 1 (in order to load bar fully complete)
        n_pages = n_pages < 1 || isNaN(n_pages) ? 1 : n_pages;
        const step = Math.floor(100 / n_pages);

        if (step * (n_pages - 1) === load_bar.progress) {
            load_bar.progress = 100;
            load_bar.complete();
            return true;
        }

        load_bar.progress += step;
        return false;
    }

    ObjectToArray(obj): Array<any> {
        const arr = [];
        Object.keys(obj).map((index) => {
            obj[index].product_count = obj[index].products.length;
            arr.push(obj[index]);
        });
        return arr;
    }
}
