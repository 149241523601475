import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'SeasonsFilter'
})
export class SeasonsFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { brand_name, season_name } = element;

            const filterList = [brand_name, season_name];

            return filterListByQuery(filterList, query);
        });
    }
}
