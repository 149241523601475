import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { WizardStepComponent } from '../wizard-step/wizard-step.component';

@Component({
    selector: 'app-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements AfterContentInit {
    @ContentChildren(WizardStepComponent)
    wizardSteps: QueryList<WizardStepComponent>;
    @Input() title: string;
    @Input() hasSaveButton = false;
    @Input() hasDoneButton = true;
    @Input() hasSendTo3plCheckbox = true;

    @Output() onStepChanged: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

    _steps: Array<WizardStepComponent> = [];
    _isConfirmed = false;

    constructor() {}

    get steps(): Array<WizardStepComponent> {
        return this._steps.filter((step) => !step.hidden);
    }

    get isConfirmed(): boolean {
        return this._isConfirmed;
    }

    get activeStep(): WizardStepComponent {
        return this.steps.find((step) => step.isActive);
    }

    set activeStep(step: WizardStepComponent) {
        if (step !== this.activeStep && !step.isDisabled) {
            this.activeStep.isActive = false;
            step.isActive = true;
            this.onStepChanged.emit(step);
        }
    }

    public get activeStepIndex(): number {
        return this.steps.indexOf(this.activeStep);
    }

    get hasNextStep(): boolean {
        return this.activeStepIndex < this.steps.length - 1;
    }

    get hasPrevStep(): boolean {
        return this.activeStepIndex > 0;
    }

    ngAfterContentInit() {
        this.wizardSteps.forEach((step) => this._steps.push(step));
        this.steps[0].isActive = true;
    }

    public goToStep(step: WizardStepComponent): void {
        if (!this.isConfirmed) {
            this.activeStep = step;
        }
    }

    public next(): void {
        if (this.hasNextStep) {
            const nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];
            this.activeStep.onNext.emit();
            nextStep.isDisabled = false;
            this.activeStep = nextStep;
        }
    }

    public previous(): void {
        if (this.hasPrevStep) {
            const prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
            this.activeStep.onPrev.emit();
            prevStep.isDisabled = false;
            this.activeStep = prevStep;
        }
    }

    public complete(): void {
        this.activeStep.onConfirmation.emit();
        this._isConfirmed = true;
    }

    public save(): void {
        this.onSave.emit();
    }
}
