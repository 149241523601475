import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
    private _token;

    constructor() {}

    validateToken(): boolean {
        this._token = localStorage.getItem('accessToken');

        // Token needs to be validate and then returns true

        return true;
    }
}
