import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'PackFilter'
})
export class PackFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { so, drop, pack } = element;

            if (!so && !drop && !pack) {
                return false;
            }
            const internal_order_num = so.internal_order_num;
            const orderdrop_num = drop.orderdrop_num;
            const ean = pack.ean;

            const filterList = [internal_order_num, orderdrop_num, ean];

            return filterListByQuery(filterList, query);
        });
    }
}
