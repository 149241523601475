export const GLOBAL = {
    carriersMap: {
        'DHE-EXPRESS': 'DHL Express',
        'DHP-FORYOU': 'DHL Benelux',
        'DHP-Europlus': 'DHL Europa',
        'CBFC-Clients Carrier': 'Clients Carrier'
    },
    FULFILMENT_PAGE_SIZE: 15,
    SALES_CHANNELS: {
        WHOLESALE: [
            1, // "Wholesale
            7 // "Own Store
        ],
        ECOMMERCE: [
            2, // "Webshop
            4 // "Private Order
        ]
    },
    SALES_CHANNELS_TYPES: {
        WHOLESALE: 'b2b',
        ECOMMERCE: 'b2c'
    }
};
