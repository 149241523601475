import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Services
import { GetdataService } from '../../../services/getdata/getdata.service';

@Component({
    selector: 'app-address-panel',
    templateUrl: './address-panel.component.html',
    styleUrls: ['./address-panel.component.css']
})
export class AddressPanelComponent implements OnInit {
    @Input() title: string;
    @Input() type: string;
    @Input() isWarehouse = false;

    @Output() onAddressSelection: EventEmitter<any> = new EventEmitter<any>();

    addresses: any = [];

    /**
     * GET all customer + addresses
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    _handleAddress(db_addresses) {
        this._push_to_addresses(db_addresses.data);

        const n_pages = Math.ceil(db_addresses.total_count / db_addresses.data.length);

        for (let page = 2; page <= n_pages; page++) {
            this._http
                .getCustomersWithAddresses(this.isWarehouse, page)
                .subscribe((db_addr) => this._push_to_addresses(db_addr.data));
        }
    }

    /**
     * Push database stuff to an array of objects (customer and address info)
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    _push_to_addresses(customers) {
        // For each customer
        customers.forEach((customer) => {
            // For each address of each customer
            customer.customer_address.forEach((addr) => {
                if (this.type === addr.address_type.toLowerCase()) {
                    this.addresses.push({
                        id: addr.id,
                        text: `${customer.name} - ${addr.address}, ${addr.zip}, ${addr.city}, ${addr.country}`,
                        customer_info: customer,
                        address_info: addr
                    });
                }
            });
        });
    }

    constructor(private _http: GetdataService) {}

    ngOnInit() {
        if (this.type === 'billing' || this.type === 'shipping') {
            this._http.getCustomersWithAddresses(this.isWarehouse, 1).subscribe(this._handleAddress);
        }
    }

    /**
     * Emmit event on selection, sending the information to the parent component
     * @author Luís Melo <lfmelo@thehuub.co>
     */
    addressSelected(event) {
        this.onAddressSelection.emit(this.addresses.find((address) => address.id === event.id));
    }
}
