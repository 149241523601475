export const FULFILMENT_CONSTANT = {
    SEARCH_FILTER_OPTIONS: [
        {
            id: 1,
            text: 'Internal order number'
        },
        {
            id: 2,
            text: 'Brand'
        },
        {
            id: 3,
            text: 'Customer'
        },
        {
            id: 4,
            text: 'Client ref'
        }
    ],
    FILTER_TYPES: ['internal_order_num', 'brand', 'customer', 'order_num'],
    FULLFILMENT_TYPES: {
        AUTOMATIC: 'automatic',
        MANUAL: 'manual'
    }
};
