import { RouterModule, Routes } from '@angular/router';
// Components
import { HomeComponent } from './components/common/home/home.component';

import { FulfilmentComponent } from './components/fulfilment/fulfilment/fulfilment.component';
import { FulfilmentInProgressComponent } from './components/fulfilment/fulfilment-in-progress/fulfilment-in-progress.component';
import { FulfilmentDoneComponent } from './components/fulfilment/fulfilment-done/fulfilment-done.component';

// Guards
import { AuthguardGuard } from './guards/authguard.guard';

const routes: Routes = [
    // COMMON
    { path: 'home', component: HomeComponent },
    // FULFILMENT
    {
        path: 'fulfilment/:type',
        canActivate: [AuthguardGuard],
        component: FulfilmentComponent,
        children: [
            { path: '', redirectTo: 'in-progress', pathMatch: 'full' },
            { path: 'in-progress', canActivate: [AuthguardGuard], component: FulfilmentInProgressComponent },
            { path: 'done', canActivate: [AuthguardGuard], component: FulfilmentDoneComponent }
        ]
    },

    { path: '**', redirectTo: 'home' }
];

export const EXT3PL_ROUTING = RouterModule.forRoot(routes);
