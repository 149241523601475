import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { FlashMessagesService } from 'ngx-flash-messages';
import { Angular2Csv } from 'angular2-csv';

import { GetdataService } from './getdata.service';
import { environment } from '../../../environments/environment';
import { FulfilmentInfoRequestDTO } from '../../../app/data/fulfilment-info';

@Injectable()
export class CommonDataService {
    private coreUrl = environment.GTW_URL + '/api/v1.0/core';
    private apiMasterDataUrl = `${this.coreUrl}/masterdata`;
    private apiWmsUrl = `${this.coreUrl}/wms`;
    private apiOmsUrl = `${this.coreUrl}/oms`;
    private apiDmsUrl = `${this.coreUrl}/dms`;

    private options = {
        headers: new HttpHeaders({ jwt: localStorage.getItem('accessToken') })
    };

    private swtOptions = {
        headers: new HttpHeaders({
            'swt-access-token': environment.SWT_TOKEN,
            'content-type': 'application/json; charset=utf-8',
            Accept: 'application/json; charset=utf-8'
        })
    }

    private fulfilmentInitialInfo: FulfilmentInfoRequestDTO = null;
    private sales_channel: Observable<any> = null;
    private warehouses: Observable<any> = null;
    private brands: Observable<any> = null;
    private status: Observable<any> = null;
    private Incoterms: Observable<any> = null;
    private customer_warehouses: Observable<any> = null;
    private carrier_service_types: Observable<any> = null;
    private logistic_units: Observable<any> = null;
    private handling_operations: Observable<any> = null;

    constructor(
        private http: HttpClient,
        private _flash_message: FlashMessagesService,
        private _http_service: GetdataService
    ) {}

    private handleError(response: HttpErrorResponse): Observable<any> {
        let error_message = `${response.status} - ${response.statusText}`;

        const body = response.error;

        if (body && body.detail !== undefined) {
            error_message = `${error_message} - ${body.detail}`;
        }

        return observableThrowError(error_message);
    }

    private handleRequest({
        url,
        dataCallback,
        withSWT
    }: {
        url: string;
        dataCallback?: (data) => Record<string, any>;
        withSWT?: boolean
    }): Observable<any> {
        return this.http.get(url, withSWT ? this.swtOptions : this.options).pipe(
            map((response: Record<string, any>) => (dataCallback ? dataCallback(response.data) : response.data)),
            publishReplay(1),
            refCount(),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    private handleCoreSWTRequest({
        url,
        dataCallback,
    }: {
        url: string;
        dataCallback?: (data) => Record<string, any>;
    }): Observable<any> {
        return this.http.get(url, this.swtOptions).pipe(
            map((response: Record<string, any>) => (dataCallback ? dataCallback(response.results) : response.results)),
            publishReplay(1),
            refCount(),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    private formatSalesChannels(salesChannelsData) {
        const formattedSalesChannels = [];

        salesChannelsData.forEach((salesChannel) => {
            const { id, name } = salesChannel;

            formattedSalesChannels.push({
                id,
                name,
                _id: id,
                _name: name,
                text: name,
                ...salesChannel
            });
        });

        return formattedSalesChannels;
    }

    private formatWarehouses(warehousesData) {
        const formattedWarehouses = [];
        warehousesData.forEach((warehouse) => {
            const { id, name, integration, reference } = warehouse;

            formattedWarehouses.push({
                id,
                name,
                _id: id,
                _name: name,
                text: name,
                isExternalWarehouse: integration !== undefined && reference  !== 'HUUB',
                ...warehouse
            });
        });

        return formattedWarehouses;
    }

    private formatBrands(brandsData) {
        const formattedBrands = [];

        brandsData.forEach((brand) => {
            const { name } = brand;

            formattedBrands.push({
                text: name,
                ...brand
            });
        });

        return formattedBrands;
    }

    private formatIncoterms(incotermsData) {
        return incotermsData.map((incoterm) => ({
            id: incoterm.code,
            text: incoterm.code + ' - ' + incoterm.name,
            ...incoterm
        }));
    }

    private formatTransportTypes(transport_types) {
        return transport_types.map((transportType) => ({
            id: transportType.code,
            text: transportType.name,
            ...transportType
        }));
    }

    private formatCargoTypes(cargo_types) {
        return cargo_types.map((cargoType) => ({
            id: cargoType.code,
            text: cargoType.name,
            ...cargoType
        }));
    }

    private formatCarrierServiceTypes(carrierServiceTypesData) {
        const formattedCarrierServiceTypes = [];

        carrierServiceTypesData.forEach((carrierServiceType) => {
            const {
                id,
                carrier_id: carrier,
                carrier_id: { name },
                service_type
            } = carrierServiceType;

            formattedCarrierServiceTypes.push({
                carrier,
                service_type: carrierServiceType,
                id,
                text: name + ' - ' + service_type,
                ...carrierServiceType
            });
        });

        return formattedCarrierServiceTypes;
    }

    private formatLogisticUnits(logisticsUnitsData) {
        const formattedLogisticUnits = [];

        logisticsUnitsData.forEach((logisticUnit) => {
            const { code, name, unit_depth, unit_height, unit_width, unit_weight, id } = logisticUnit;

            formattedLogisticUnits.push({
                id,
                name,
                code,
                unit_depth: unit_depth !== null ? unit_depth : 0,
                unit_height: unit_height !== null ? unit_height : 0,
                unit_width: unit_width !== null ? unit_width : 0,
                unit_weight: unit_weight !== null ? unit_weight : 0,
                text: code,
                ...logisticUnit
            });
        });

        return formattedLogisticUnits;
    }

    private formatHandlingOperations(handlingOperationsData) {
        const formattedHandlingOperations = [];

        handlingOperationsData.forEach((handlingOperation) => {
            const { id, name } = handlingOperation;

            formattedHandlingOperations.push({
                id,
                text: name,
                ...handlingOperation
            });
        });

        return formattedHandlingOperations;
    }

    async getFulfilmentInitialInfo(): Promise<FulfilmentInfoRequestDTO> {
        if (!this.fulfilmentInitialInfo) {
            const brands = await this.getBrands().toPromise();
            const warehouses = await this.getWarehouses().toPromise();
            const incoterms = await this.getIncoterms().toPromise();
            const transportTypes = await this.getTransportTypes().toPromise();
            const cargoTypes = await this.getCargoTypes().toPromise();

            const formattedData = {
                brands: brands,
                warehouses: warehouses,
                incoterms: incoterms,
                transport_types: transportTypes,
                cargo_types: cargoTypes
            };

            // Just to keep the process done in the other requests
            this.warehouses = of(formattedData.warehouses);

            this.fulfilmentInitialInfo = formattedData;
        }

        return this.fulfilmentInitialInfo;
    }

    getBrands(): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/masterdata/brands/`;

        const dataCallback = (data) => this.formatBrands(data);
        // This request must use a swt token
        const brands = this.handleCoreSWTRequest({ url, dataCallback });

        return brands;
    }

    getWarehouses(): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/wms/warehouses/`;

        const dataCallback = (data) => this.formatWarehouses(data);
        const warehouses = this.handleCoreSWTRequest({ url, dataCallback });

        return warehouses;
    }

    getIncoterms(): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/dms/incoterms/`;

        const dataCallback = (data) => this.formatIncoterms(data);
        const incoterms = this.handleCoreSWTRequest({ url, dataCallback });

        return incoterms;
    }

    getTransportTypes(): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/dms/transport-types/`;

        const dataCallback = (data) => this.formatTransportTypes(data);
        const transportTypes = this.handleCoreSWTRequest({ url, dataCallback });

        return transportTypes;
    }

    getCargoTypes(): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/dms/cargo-types&`;

        const dataCallback = (data) => this.formatCargoTypes(data);
        const cargoTypes = this.handleCoreSWTRequest({ url, dataCallback });

        return cargoTypes;
    }

    getSalesChannel(): Observable<any> {
        if (!this.sales_channel) {
            const url = `${this.apiOmsUrl}/saleschannel/`;

            const dataCallback = (data) => this.formatSalesChannels(data);

            this.sales_channel = this.handleRequest({ url, dataCallback });
        }

        return this.sales_channel;
    }

    getStatus(): Observable<any> {
        if (!this.status) {
            const url = `${this.apiOmsUrl}/status/`;

            this.status = this.handleRequest({ url });
        }
        return this.status;
    }

    getCustomerWarehouses(): Observable<any> {
        if (!this.customer_warehouses) {
            const url = `${this.apiMasterDataUrl}/customers_search/?is_warehouse=True`;

            this.customer_warehouses = this.handleRequest({ url });
        }
        return this.customer_warehouses;
    }

    getCarrierServiceTypes(): Observable<any> {
        if (!this.carrier_service_types) {
            const url = `${this.apiDmsUrl}/service_types/`;

            const dataCallback = (data) => this.formatCarrierServiceTypes(data);

            this.carrier_service_types = this.handleRequest({ url, dataCallback });
        }
        return this.carrier_service_types;
    }

    getLogisticUnits(): Observable<any> {
        if (!this.logistic_units) {
            const url = `${this.apiWmsUrl}/handling_packs/`;

            const dataCallback = (data) => this.formatLogisticUnits(data);

            this.logistic_units = this.handleRequest({ url, dataCallback });
        }
        return this.logistic_units;
    }

    getHandlingOperations(): Observable<any> {
        if (!this.handling_operations) {
            const url = `${this.apiWmsUrl}/handling_operations/`;

            const dataCallback = (data) => this.formatHandlingOperations(data);

            this.handling_operations = this.handleRequest({ url, dataCallback });
        }
        return this.handling_operations;
    }

    getServiceTypes(warehouseId): Observable<any> {
        const url = `${environment.URL_CORE_API}/core/v1.0/dms/external_service_types/?warehouse_id=${warehouseId}`;

        return this.handleCoreSWTRequest({ url });
    }

    // Filters Step1
    getSoDropsFiltered(so_drops, brand_selected, date_range_filter): Array<any> {
        return so_drops.filter(
            (so_drop) => this._warehouseMatch(so_drop, brand_selected) && this._datesMatch(so_drop, date_range_filter)
        );
    }

    _warehouseMatch(so_drop, brand_selected): boolean {
        if (brand_selected.length === 0) {
            return true;
        }
        // Brand need to match
        return so_drop.so.huubclient_id === brand_selected[0].id;
    }

    /**
     * Helper to filter in order to the date range selected
     */
    _datesMatch(so_drop, date_range_filter): boolean {
        // No dates selected
        if (date_range_filter === undefined || date_range_filter === null) {
            return true;
        }

        const begin_timestamp = date_range_filter.beginEpoc;
        const end_timestamp = date_range_filter.endEpoc;
        const order_timestamp = new Date(so_drop.drop.orderdrop_date).getTime() / 1000;

        if (begin_timestamp === undefined || end_timestamp === undefined) {
            return true;
        }

        return order_timestamp > begin_timestamp && order_timestamp < end_timestamp;
    }

    exportCSV(keys, data, file_name): any {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: false,
            useBom: false, // blank line
            headers: Object.keys(keys)
        };

        return new Angular2Csv(data, file_name, options);
    }

    // Duplicated on Helpers Service
    _flashMsg(msg, type): void {
        /**
         * When error is: Product with id x dows not exist.
         */
        const regex_pattern = /.*Product with id \d+ was not found\./g;
        if (regex_pattern.exec(msg) !== null) {
            const id = parseInt(msg.replace(/^.+ Product with id /g, '').replace(/ was not found\./g, ''), 10);
            this._http_service.getProducts('id=' + id).subscribe((data) => {
                const product = data.data[0];
                msg = `Product: ${product.huubclient} | ${product.season} | ${product.reference} not found in 3pl.`;
                this._popupMsg(type, msg);
            });
        } else {
            this._popupMsg(type, msg);
        }
    }

    _popupMsg(type, msg): void {
        switch (type) {
            case 'success':
                return this._flash_message.show(msg, {
                    classes: ['alert', 'alert-success'],
                    timeout: 3000
                });
            case 'warning':
                return this._flash_message.show('WARNING: ' + msg, {
                    classes: ['alert', 'alert-warning'],
                    timeout: 10000
                });
            case 'error':
                return this._flash_message.show('ERROR: ' + msg, {
                    classes: ['alert', 'alert-danger'],
                    timeout: 10000
                });
        }
    }
}
