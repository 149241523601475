import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        const token = this.route.snapshot.queryParams.token;
        if (token !== undefined) {
            localStorage.setItem('accessToken', token);
        }
    }
}
