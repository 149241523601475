import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Services
import { GetdataService } from '../../../services/getdata/getdata.service';
import { LoadingStore } from '../../loading/loading.store';
import { CommonDataService } from '../../../services/getdata/common-data.service';
import { GLOBAL } from '../../../constants/global.constant';

import 'bootstrap';

@Component({
    selector: 'app-fulfilment-done',
    templateUrl: './fulfilment-done.component.html',
    styleUrls: ['./fulfilment-done.component.css']
})
export class FulfilmentDoneComponent implements OnInit, AfterViewChecked {
    private salesChannelTypes = null;
    private fulfilmentType = '';
    filterQuery: any;

    fulfilmentList: any = [];
    brands: any = [];
    public selectedWarehouse: any = [];
    public warehouses: any = [];

    constructor(
        private _http: GetdataService,
        private _common_data: CommonDataService,
        private activatedRoute: ActivatedRoute
    ) {}

    /**
     * WE NEED TO REFORMULATE THIS...
     *
     * TOO MUCH LOAD
     *
     */
    private formatFulfilmentsDestinationsArray(orders) {
        // For each fulfilment, get destinations
        orders.forEach((order) => {
            let qnt_expected = 0;
            let qnt_fulfiled = 0;

            // for each destinations, get units
            order.ship_trf_dest_units.forEach((unit) => {
                // for each unit, get items to calculate expected and fulfiled quantities
                unit.ship_trf_item_in_unit.forEach((item) => {
                    qnt_expected += item.qnt_expected;
                    qnt_fulfiled += item.qnt_fulfiled;
                });
            });

            this.fulfilmentList.push({
                so_drop_name: order.so_drop_id.so_internal_order_num + ' - Drop #' + order.so_drop_id.drop_num,
                so_order_num: order.so_drop_id.so_order_num,
                so_huubclient: order.so_drop_id.so_huubclient,
                so_customer: order.so_drop_id.so_customer,
                drop_status: order.so_drop_id.drop_status,
                drop_date: order.so_drop_id.drop_date,
                created_at: order.created_at,
                shipping_transfer_id: order.shipping_transfer_id,
                shipping_transfer_status: order.status,
                country: order.destination_country,
                qnt_expected,
                qnt_fulfiled,
                http_request_status: order.http_request_status
            });
        });
    }

    public ngOnInit() {
        this.activatedRoute.parent.params.subscribe((params: any) => {
            this.fulfilmentType = params['type'];

            if (this.fulfilmentType === 'ecommerce') {
                this.salesChannelTypes = GLOBAL.SALES_CHANNELS.ECOMMERCE;
            }

            if (this.fulfilmentType === 'wholesale') {
                this.salesChannelTypes = GLOBAL.SALES_CHANNELS.WHOLESALE;
            }
        });

        LoadingStore.changeStatusSubject.asObservable().subscribe((newLoadingStatus) => {
            if (!newLoadingStatus) {
                this.fulfilmentList.forEach((fulfilment) => {
                    fulfilment.requestIdentifier = fulfilment.http_request_status.request_identifier;
                    if (fulfilment.shipping_transfer_status === 'Data sent') {
                        fulfilment.shipping_transfer_status = `${fulfilment.http_request_status.name}`;
                        fulfilment.statusCode = fulfilment.http_request_status.code;
                        switch (fulfilment.http_request_status.name) {
                            case 'Data Sent':
                                fulfilment.statusLabel = 'label label-width-110 label-success';
                                break;
                            case 'Sending':
                                fulfilment.statusLabel = 'label label-width-110 label-default';
                                break;
                            case 'Failed':
                                fulfilment.statusLabel = 'label label-width-110 label-danger';
                                break;
                        }
                    }
                    if (fulfilment.shipping_transfer_status === 'Received at WH') {
                        fulfilment.statusLabel = 'label label-width-110 label-info';
                    }
                });
            }
        });

        this._common_data.getWarehouses().subscribe((warehouses) => {
            this.warehouses = warehouses.filter((warehouse) => warehouse.isExternalWarehouse);
            this.selectedWarehouse = [this.warehouses[0]];
            this.getOpenShippingTransferFulfilments();
        });
    }

    public ngAfterViewChecked() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover',
            container: 'body'
        });
    }

    public onCancelFulfilment(fulfilment) {
        this._http.cancelFulfilment(fulfilment.requestIdentifier, this.fulfilmentType).subscribe(
            () => {
                fulfilment.shipping_transfer_status = 'Cancelled';
                fulfilment.statusCode = '';
                setTimeout(() => {
                    fulfilment.hide = true;
                }, 10000);
            },
            (error) => {
                if (error.code >= 500 || error.code === 401) {
                    this._common_data._flashMsg('ERROR: Something went wrong. Please, try again.', 'error');
                } else {
                    this._common_data._flashMsg(
                        'ERROR: There are some issues with this information. Please, restart the process.',
                        'error'
                    );
                }
            }
        );
    }

    public onResendFulfilment(fulfilment) {
        fulfilment.buttonDisabled = true;
        this._http.resendFulfilment(fulfilment.requestIdentifier).subscribe(
            () => {
                fulfilment.shipping_transfer_status = 'Sending';
                fulfilment.statusLabel = 'label label-width-110 label-default';
                fulfilment.statusCode = '';
            },
            (error) => {
                if (error.code >= 500 || error.code === 401) {
                    this._common_data._flashMsg('ERROR: Something went wrong. Please, try again.', 'error');
                } else {
                    this._common_data._flashMsg(
                        'ERROR: There are some issues with this information. Please, restart the process.',
                        'error'
                    );
                }
                fulfilment.buttonDisabled = false;
            }
        );
    }

    getOpenShippingTransferFulfilments() {
        LoadingStore.loadingSubject.next({
            id: 'getOpenShippingTransferFulfilments_1',
            action: 'show'
        });

        this._http
            .getOpenShippingTransferFulfilments(1, this.salesChannelTypes, this.selectedWarehouse[0].id)
            .subscribe((db_ship_trf) => {
                // console.log(db_ship_trf);
                const n_pages = Math.ceil(db_ship_trf.total_count / db_ship_trf.data.length);
                this.fulfilmentList = [];
                this.formatFulfilmentsDestinationsArray(db_ship_trf.data);
                for (let page = 2; page <= n_pages; page++) {
                    LoadingStore.loadingSubject.next({
                        id: `getOpenShippingTransferFulfilments_${page}`,
                        action: 'show'
                    });
                    this._http
                        .getOpenShippingTransferFulfilments(page, this.salesChannelTypes, this.selectedWarehouse[0].id)
                        .subscribe((db_ship_trf_2) => {
                            this.formatFulfilmentsDestinationsArray(db_ship_trf_2.data);
                            LoadingStore.loadingSubject.next({
                                id: `getOpenShippingTransferFulfilments_${page}`,
                                action: 'hide'
                            });
                        });
                }
                LoadingStore.loadingSubject.next({
                    id: 'getOpenShippingTransferFulfilments_1',
                    action: 'hide'
                });
            });
    }

    public warehouseChanged(newWarehouse) {
        this.selectedWarehouse = [newWarehouse];
        this.getOpenShippingTransferFulfilments();
    }
}
