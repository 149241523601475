import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GLOBAL } from './../../constants/global.constant';

@Injectable()
export class GetdataService {
    constructor(private http: HttpClient) {}
    private _api_masterdata_url = environment.GTW_URL + '/api/v1.0/core/masterdata';
    private _api_oms_url = environment.GTW_URL + '/api/v1.0/core/oms';
    private _api_3pl_url = environment.GTW_URL + '/api/v1.0/ext/3pl';
    private _core_api_url = environment.URL_CORE_API;
    private _external_token = localStorage.getItem('accessToken');

    private options = {
        headers: new HttpHeaders({
            'jwt-access-token': this._external_token,
            'content-type': 'application/json; charset=utf-8',
            Accept: 'application/json; charset=utf-8'
        })
    };

    private swtOptions = {
        headers: new HttpHeaders({
            'swt-access-token': environment.SWT_TOKEN,
            'content-type': 'application/json; charset=utf-8',
            Accept: 'application/json; charset=utf-8'
        })
    };

    private handleError(response): Observable<any> {
        let error_message = `${response.status} - ${response.statusText}`;
        const body = response.error;

        if (body && body.detail !== undefined) {
            if (body.detail.details) {
                error_message = body.detail.details;
            } else {
                error_message = `${error_message} - ${body.detail}`;
            }
        }

        return observableThrowError(error_message);
    }

    public getSpokeUrl(path): string {
        return environment.SPOKE_URL + path;
    }

    public resendFulfilment(requestIdentifier): Observable<any> {
        const url = `${this._api_3pl_url}/shipping_transfer_fulfilment_resend/`;
        const body = {
            request: 'shipment_transfer_resend_request',
            request_identifier: requestIdentifier
        };

        return this.http.post(url, body, this.options);
    }

    public cancelFulfilment(requestIdentifier, requestType): Observable<any> {
        const url = `${this._api_3pl_url}/shipping_transfer_fulfilment_delete/`;
        const body = {
            request: 'shipment_transfer_delete_request',
            request_identifier: requestIdentifier,
            request_type: requestType
        };

        return this.http.put(url, body, this.options);
    }

    getProducts(filters, page = 1): Observable<any> {
        return this.http
            .get(this._api_masterdata_url + `/product_model_search/?page=${page}&${filters}`, this.options)
            .pipe(
                map((res: any) => {
                    const products = {
                        data: [],
                        total_count: res.count
                    };

                    // Add checked option to each product
                    res.data.map((elem) => {
                        elem.checked = false;
                        products.data.push(elem);
                        return elem;
                    });

                    return products;
                }),
                catchError((err: HttpErrorResponse) => this.handleError(err))
            );
    }

    getCustomersWithAddresses(is_warehouse = false, page = 1): Observable<any> {
        const is_wh = is_warehouse ? 'True' : 'False';
        const url = `${this._api_masterdata_url}/customers_search/?page=${page}&is_warehouse=${is_wh}`;
        return this.http.get(url, this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    getCustomersWithAddressesByIds(customers_ids): Observable<any> {
        const url = `${this._api_masterdata_url}/customers_search/?id=[${customers_ids}]`;
        return this.http.get(url, this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    getProductsValidation(productsIds: Array<number>, warehouse_ids: Array<number>): Observable<any> {
        const body = {
            request: 'masterdata_products_validation_status',
            products: productsIds,
            warehouse_ids
        };
        const url = `${this._api_3pl_url}/product_validation_status/`;

        return this.http.post(url, body, this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    getSoDropsWithoutDropItems(
        page = 1,
        brand_id = '',
        status_id = [4, 5],
        sales_channel_id = [1, 2, 3]
    ): Observable<any> {
        let url = `${this._api_oms_url}/so_search_without_order_drop_items/?page=${page}&`;
        url += `status_id=[${status_id.join(',')}]&huubclient_id=${brand_id}&sales_channel_id=[${sales_channel_id.join(
            ','
        )}]`;

        return this.http.get(url, this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    updateOrderDrop(body): Observable<any> {
        const url = `${this._core_api_url}/core/v1.0/oms/so/drop/`;

        return this.http.put(url, body, this.swtOptions).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    getSoDropsFulfilment(
        communication_type = 'manual',
        salesChannelType,
        warehouseId,
        page = 1,
        search?,
        pageSize = GLOBAL.FULFILMENT_PAGE_SIZE
    ): Observable<any> {
        let body: any = {
            sales_channel_type: salesChannelType,
            page_size: pageSize,
            warehouse_id: warehouseId,
            communication_type
        };

        if (search && search.searchFilter && search.searchType) {
            body = {
                ...body,
                search_type: search.searchType,
                search_filter: search.searchFilter
            };
        }

        return this.http
            .post(`${this._core_api_url}/core/v1.0/oms/so/drop/3pl_fulfilment_list/?page=${page}`, body, this.swtOptions)
            .pipe(
                map((response) => response),
                catchError((err: HttpErrorResponse) => this.handleError(err))
            );
    }

    getSoDropStock(orderdropId): Observable<any> {
        const url = `${this._core_api_url}/core/v1.0/oms/external_fulfilment_capacity/3pl_orderdrop_stock/?orderdrop_id=${orderdropId}`;

        return this.http.get(url, this.swtOptions).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    sendProductext3pl(data_to_send): Observable<any> {
        return this.http.post(this._api_3pl_url + '/product_model', data_to_send, this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    updateShipmentOutbound(body): Observable<any> {
        const url = `${this._core_api_url}/core/v1.0/dms/shipment/outbound/`;

        return this.http.put(url, body, this.swtOptions).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    /* *******************************
     * ******** FULFILMENT **********
     * *******************************/

    getOpenShippingTransferFulfilments(page = 1, salesChannelId, warehouseId): Observable<any> {
        const url =
            `${this._api_3pl_url}/shipping_transfer_destination` +
            `?page=${page}&crossdocking=False&status_id=[1,2]` +
            `&sales_channel_id=[${salesChannelId}]&warehouse_id=${warehouseId}`;

        return this.http.get(url, this.options).pipe(
            map((res: any) => ({
                data: res.data,
                total_count: res.count
            })),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    postFulfilment(data): Observable<any> {
        return this.http.post(environment.URL_3PL + '/ext/v1.0/3pl/shipping/transfer/fulfilment/', data, this.swtOptions).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }
}
