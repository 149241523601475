import { LoadingComponent } from './components/loading/loading.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, MessagingService, RoutingService } from '@maersk-global/huub-utils';
import { environment } from '../environments/environment';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    @ViewChild('loading', { static: false }) loading: LoadingComponent;

    eclPlatformUrl: string;
    isBeingDisplayedAtECL = false;

    constructor(private router: Router) {
        this.eclPlatformUrl = environment.ECL_PLATFORM_URL;
        this.isBeingDisplayedAtECL = environment.IS_ECL;
    }

    ngOnInit(): void {
        if (this.isBeingDisplayedAtECL) {
            this.addAuthListener();
            RoutingService.addRouteListener(this.router, this.eclPlatformUrl);
        }
    }

    addAuthListener(): void {
        window.addEventListener('message', (message) => {
            MessagingService.handleSetTokenMessageEvent(message, [this.eclPlatformUrl], this.router, () => {});
        });
    }
}
