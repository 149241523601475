import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'ProductsFilter'
})
export class ProductsFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { product } = element;

            const newElement = product ? product : element;

            const { product_subfamily, ean, reference, huubclient, season, supplier, model_name } = newElement;

            const filterList = [product_subfamily, ean, reference, huubclient, season, supplier, model_name];

            return filterListByQuery(filterList, query);
        });
    }
}
