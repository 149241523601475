import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SeasonModalFilter' })
export class SeasonModalFilterPipe implements PipeTransform {
    public transform(seasons: any[], season_selected: any, filter: string): any[] {
        const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

        if (!seasons || !seasons.length || isEmpty(season_selected)) {
            return [];
        }

        if (!filter) {
            return seasons;
        }

        return seasons.filter((season) => season.id === season_selected.id)[0].items;
    }
}
