import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, animate, transition, query } from '@angular/animations';
import { BsModalComponent } from 'ng2-bs3-modal';

// Constants
import { GLOBAL } from '../../../constants/global.constant';
import { FULFILMENT_CONSTANT } from './fulfilment-in-progress.constant';

// Services
import { GetdataService } from '../../../services/getdata/getdata.service';
import { CommonDataService } from '../../../services/getdata/common-data.service';

// Helpers
import { Helpers } from '../../../services/helpers/Helpers';

// Stores
import { LoadingStore } from '../../loading/loading.store';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-fulfilment-in-progress',
    templateUrl: './fulfilment-in-progress.component.html',
    styleUrls: ['./fulfilment-in-progress.component.css'],
    animations: [
        trigger('enterWarning', [
            transition(':enter', [
                query('.drops-list__warning-container', style({ height: '0px' }), { optional: true }),
                query('.drops-list__warning-container', animate('300ms ease-out', style({ height: '48px' })), {
                    optional: true
                })
            ]),
            transition(':leave', [
                query('.drops-list__warning-container', style({ height: '48px' }), { optional: true }),
                query('.drops-list__warning-container', animate('300ms ease-out', style({ height: '0px' })), {
                    optional: true
                })
            ])
        ])
    ]
})
export class FulfilmentInProgressComponent implements OnInit {
    private salesChannelTypes = null;

    private originWarehouseToSend: any = [];

    private carrierInfo: any = [];

    public communicationType = FULFILMENT_CONSTANT.FULLFILMENT_TYPES.MANUAL; // Default value
    public communicationTypes = FULFILMENT_CONSTANT.FULLFILMENT_TYPES;

    public fulfilmentType = '';
    public loadingStockSet = new Set();

    @ViewChild('productsInModal', { static: true }) modal: BsModalComponent;
    public filterQuery: any;
    public filterProductData: any;
    public productsToShow: Array<any> = [];
    public dropsListTable: Array<any> = [];

    public brands: any = [];
    public soDrops: any = [];
    public warehouses: any = [];
    public incoterms: any = [];
    public cargoTypes: any = [];
    public transportTypes: any = [];

    public searchFilterOptions = FULFILMENT_CONSTANT.SEARCH_FILTER_OPTIONS;
    public searchFilterSelected = [this.searchFilterOptions[0]];

    public isDoneValid = false;

    public paginator = {
        currentPage: 1,
        totalItems: 0,
        pageSize: GLOBAL.FULFILMENT_PAGE_SIZE
    };

    public selectedBrand: any = [];
    public selectedWarehouse: any = [];

    constructor(
        private commonDataService: CommonDataService,
        private getDataService: GetdataService,
        private activatedRoute: ActivatedRoute
    ) {
        LoadingStore.loadingSubject.next({
            id: 'getFulfilmentInitialInfo',
            action: 'show'
        });
        LoadingStore.loadingSubject.next({
            id: 'getServiceLevelsByWarehouseId',
            action: 'show'
        });


        this.commonDataService.getFulfilmentInitialInfo().then((data): any => {
            this.warehouses = data.warehouses.filter((warehouse) => warehouse.isExternalWarehouse);
            this.selectedWarehouse = [this.warehouses[0]];
            this.originWarehouseToSend = data.warehouses.filter((warehouse) => !warehouse.isExternalWarehouse);

            this.getServiceLevelsByWarehouseId();
            this.brands = data.brands;
            this.incoterms = data.incoterms;
            this.cargoTypes = data.cargo_types;
            this.transportTypes = data.transport_types;
            this.incoterms = data.incoterms;

            this.activatedRoute.parent.params.subscribe((params: any) => {
                this.fulfilmentType = params['type'];

                if (this.fulfilmentType === 'ecommerce') {
                    this.salesChannelTypes = GLOBAL.SALES_CHANNELS_TYPES.ECOMMERCE;
                }

                if (this.fulfilmentType === 'wholesale') {
                    this.salesChannelTypes = GLOBAL.SALES_CHANNELS_TYPES.WHOLESALE;
                }

                this.getFulfilmentList();
            });

            LoadingStore.loadingSubject.next({
                id: 'getFulfilmentInitialInfo',
                action: 'hide'
            });
            LoadingStore.loadingSubject.next({
                id: 'getServiceLevelsByWarehouseId',
                action: 'hide'
            });
        }, (err) => {
        });
    }

    private initNewShippingData(so_drop): boolean {
        if (!so_drop.new_shipping_data) {
            so_drop.new_shipping_data = {
                incoterm: this.incoterms[0].incoterm,
                cargo_type: this.cargoTypes[0].code,
                transport_type: this.incoterms[0].incoterm,
                carrier: '',
                carrier_service_type: ''
            };

            return true;
        }

        return false;
    }

    private validateDone(selectedDrop) {
        const { checked, drop_stock_info, new_shipping_data } = selectedDrop;

        const hasStockInfo = drop_stock_info && !!drop_stock_info;

        const hasStock =
            hasStockInfo &&
            drop_stock_info.distinct_items_which_can_be_fulfiled === drop_stock_info.distinct_items_to_fulfil;

        this.isDoneValid = checked
            ? checked && new_shipping_data && new_shipping_data.carrier !== '' && hasStock
            : this.isDoneValid;
    }

    private formatDrop(so_drop): void {
        const {
            estimated_drop_date: estimatedDropDate,
            service_level: serviceLevel,
            customer,
            customer: { shipping_address, name: customerName },
            drop_items,
            id: soDropId,
            outbound,
            outbound: { ship_limit_date: shipLimitDate },
            sales_order: salesOrder,
            sales_order: {
                id: salesOrderId,
                brand_name: dataBrand,
                status_name: dataStatus,
                order_date: orderDate,
                sales_channel_name: dataSalesChannel
            }
        } = so_drop;

        const dropData: Record<string, any> = {
            so: salesOrder,
            customer,
            tax: {}, // will be a customer address
            duties: {}, // will be a customer address
            drop: so_drop,
            drop_items,
            total_items: 0,
            items: [],
            drop_id: soDropId,
            spoke_url: this.getDataService.getSpokeUrl(`/private/so/drop/so_id/${salesOrderId}`),
            checked: false,
            selectedIncoterm: this.incoterms[0],
            selectedCargoType: this.cargoTypes[0],
            selectedTransportType: this.transportTypes[0],
            selectedCarrier: {},
            destination_address: {
                ...shipping_address,
                country: shipping_address.country_name
            }
        };

        const dataOrderDate = orderDate && new Date(orderDate).toISOString().split('T')[0];
        const dataEstimatedOrderDate = estimatedDropDate && new Date(estimatedDropDate).toISOString().split('T')[0];
        const dataShipLimitDate = shipLimitDate && shipLimitDate.split('T')[0];

        if (dataSalesChannel) {
            dropData.sales_channel = dataSalesChannel;
        }
        if (dataStatus) {
            dropData.status = dataStatus;
        }
        if (dataBrand) {
            dropData.brand = dataBrand;
        }

        dropData.estimated_drop_date = dataEstimatedOrderDate;
        dropData.orderdrop_date_formated = dataOrderDate;
        dropData.ship_limit_date = dataShipLimitDate;

        if (outbound) {
            const {
                preselected_carrier: preselectedCarrier,
                preselected_carrier_service_type: preselectedCarrierServiceType,
                return_carrier: returnCarrier,
                return_carrier_service_type: returnCarrierServiceType,
                incoterm: incoterm_code,
                incoterms: incoterms_code,
                cargo_type: cargoTypes_code,
                transport_type: transportTypes_code,
                contract_ref: contractRef
            } = outbound;

            dropData.selectedIncoterm =
                this.incoterms.find(
                    (incoterm) => incoterms_code === incoterm.code || incoterm.code === incoterm_code
                ) || null;
            dropData.selectedCargoType = this.cargoTypes.find((cargoType) => cargoType.code === cargoTypes_code) || {};
            dropData.selectedTransportType =
                this.transportTypes.find((transportType) => transportType.code === transportTypes_code) || {};
            dropData.new_shipping_data = {
                incoterm: dropData.selectedIncoterm ? dropData.selectedIncoterm.code : {},
                cargoType: dropData.selectedCargoType ? dropData.selectedCargoType.code : {},
                transportType: dropData.selectedTransportType ? dropData.selectedTransportType.code : {},
                carrier: preselectedCarrier,
                carrier_service_type: preselectedCarrierServiceType,
                return_carrier: returnCarrier,
                return_service_type: returnCarrierServiceType,
                service_level: serviceLevel
            };

            dropData.contract_ref = contractRef;

            dropData.selectedCarrier = this.carrierInfo.find(
                (carrierInfo) =>
                    carrierInfo.carrier === preselectedCarrier &&
                    carrierInfo.service_type === preselectedCarrierServiceType
            ) || { id: '', text: 'Empty' };

            this.validateDone(dropData);
        }

        this.soDrops.push(dropData);
    }

    private getServiceLevelsByWarehouseId(): void {
        this.carrierInfo = [];
        const serviceTypes$ = this.commonDataService.getServiceTypes(this.selectedWarehouse[0].id);

        serviceTypes$.subscribe((services) => {
            services.map((service) => {
                const { id, service_name, carrier, service_type } = service;

                const carrierInfo = {
                    id,
                    text: `${carrier}: ${service_name}`,
                    carrier,
                    service_type
                };

                this.carrierInfo.push(carrierInfo);
            });
        });
    }

    private getDropItems(drop_items): Array<any> {
        const dropItems = [];

        drop_items.forEach((item) => {
            const {
                country_of_origin,
                currency_iso_code,
                hscode,
                product_material,
                product_model_id,
                qnt_estimated_allocation: qnt_expected,
                price_base: unit_price_base,
                price_total,
                price_vat: unit_price_vat
            } = item;

            dropItems.push({
                country_of_origin,
                currency_iso_code,
                hscode,
                product_material,
                product_model_id,
                qnt_expected,
                qnt_fulfiled: 0,
                price_total,
                unit_price_base,
                unit_price_vat
            });
        });

        if (dropItems.length === 0) {
            throw new TypeError('Drop items list is empty.');
        }

        return dropItems;
    }

    private getOrdersDetails(): Array<any> {
        const order_details = [];

        this.soDrops
            .filter((e) => e.checked === true)
            .forEach((so_drop) => {
                this.initNewShippingData(so_drop);

                const {
                    customer: {
                        id: customerId,
                        name: customerName,
                        email: customerEmail,
                        phone: customerPhone,
                        billing_address: customerBillingAddress,
                        tax_number: customerTaxNumber
                    },
                    destination_address,
                    drop: {
                        ean: dropEan,
                        id: drop_id,
                        estimated_drop_date,
                        warehouse_fulfilment_type_allowed,
                        orderdrop_num: drop_num
                    },
                    orderdrop_date_formated: dropDateFormatted,
                    drop_items,
                    new_shipping_data: {
                        carrier: newShippingDataCarrier,
                        carrier_service_type: newShippingDataCarrierServiceType,
                        transportType: newTransportType,
                        cargoType: newCargoType,
                        incoterm: newShippingDataIncoterm,
                        return_carrier,
                        return_service_type,
                        service_level
                    },
                    so: {
                        id: so_id,
                        internal_order_num: so_internal_order_num,
                        order_date: so_order_date,
                        order_num: so_order_num,
                        sales_channel_name: so_sales_channel,
                        generate_return_label,
                        print_return_label,
                        is_stock_transfer,
                        brand_name: dropBrand,
                        status_name: so_status
                    },
                    status: drop_status
                } = so_drop;

                const customerInfo = {
                    customer_id: customerId,
                    customer_name: customerName,
                    email: customerEmail,
                    phone: customerPhone
                };

                // Prepare 'destination' details
                const destination = {
                    ...destination_address,
                    ...customerInfo
                };

                // Prepare 'billing' details
                const billing = {
                    ...customerBillingAddress,
                    ...customerInfo,
                    country: customerBillingAddress.country_name
                };

                // Prepare 'tax' and 'duties' details
                const taxAndDutiesAddress = {
                    name: customerName,
                    address: destination_address.address,
                    city: destination_address.city,
                    country: destination_address.country_name,
                    country_code: destination_address.country_code,
                    zip: destination_address.zip
                };

                const tax = {
                    ...taxAndDutiesAddress,
                    tax_number: customerTaxNumber || ''
                };

                const duties = {
                    ...taxAndDutiesAddress,
                    duties_number: customerTaxNumber !== null ? customerTaxNumber : ''
                };

                order_details.push({
                    order: {
                        so_id,
                        so_internal_order_num,
                        so_order_num,
                        so_order_date,
                        so_status,
                        so_sales_channel,
                        so_huubclient: dropBrand,
                        so_customer: customerName,
                        so_is_stock_transfer: is_stock_transfer,
                        drop_id,
                        drop_num,
                        drop_date: dropDateFormatted,
                        drop_status,
                        drop_ean: dropEan != null ? dropEan : '',
                        warehouse_fulfilment_type_allowed
                    },
                    shipping: {
                        service_level,
                        estimated_delivery_date: estimated_drop_date,
                        incoterms: newShippingDataIncoterm,
                        cargo_type: newCargoType,
                        transport_type: newTransportType,
                        carrier: newShippingDataCarrier,
                        carrier_service_type: newShippingDataCarrierServiceType,
                        generate_return_label,
                        print_return_label,
                        return_carrier,
                        return_service_type,
                        destination,
                        billing,
                        tax,
                        duties
                    },
                    packing_list: this.getDropItems(drop_items),
                    handling: {
                        pack_type: [],
                        operations: [],
                        other_notes: ''
                    }
                });
            });

        return order_details;
    }

    private buildRequestData(): Record<string, any> {
        const date = Helpers.prototype.formatDate();

        return {
            request: 'shipping_transfer_fulfilment',
            date,
            request_type: this.fulfilmentType,
            transfers: {
                origin_warehouse_id: this.originWarehouseToSend[0].id,
                origin_warehouse_name: this.originWarehouseToSend[0].text,
                destination_warehouse_id: this.selectedWarehouse[0].id,
                destination_warehouse_name: this.selectedWarehouse[0].text,
                orders: this.getOrdersDetails()
            }
        };
    }

    ngOnInit(): void {}

    public getDropStock(dropIndex): void {
        this.loadingStockSet.add(dropIndex);
        this.getDataService.getSoDropStock(this.soDrops[dropIndex].drop_id).subscribe((stock_data) => {
            this.loadingStockSet.delete(dropIndex);
            this.soDrops[dropIndex].drop_stock_info = stock_data.data;

            this.validateDone(this.soDrops[dropIndex]);
        });
    }

    public getFulfilmentList(page = 1) {
        LoadingStore.loadingSubject.next({
            id: `getFulfilmentList_${this.paginator.currentPage}`,
            action: 'show'
        });

        const search: any = {};
        if (this.searchFilterSelected[0].id === 2 && this.selectedBrand.length === 1) {
            search.searchType = FULFILMENT_CONSTANT.FILTER_TYPES[this.searchFilterSelected[0].id - 1];
            search.searchFilter = this.selectedBrand[0].text;
        } else if (this.searchFilterSelected[0].id !== 2 && this.filterQuery !== undefined && this.filterQuery !== '') {
            search.searchType = FULFILMENT_CONSTANT.FILTER_TYPES[this.searchFilterSelected[0].id - 1];
            search.searchFilter = this.filterQuery;
        }

        this.getDataService
            .getSoDropsFulfilment(
                this.communicationType,
                this.salesChannelTypes,
                this.selectedWarehouse[0].id,
                page,
                search
            )
            .subscribe((response) => {
                if (!response || !response.data) {
                    return;
                }

                const { page_number, total_items_count } = response.paginator;
                const so_drops = response.data;

                this.paginator.currentPage = page_number;
                this.paginator.totalItems = total_items_count;

                this.soDrops = [];

                so_drops.forEach((so_drop) => this.formatDrop(so_drop));

                LoadingStore.loadingSubject.next({
                    id: `getFulfilmentList_${this.paginator.currentPage}`,
                    action: 'hide'
                });
            });
    }

    public sendTo3pl(): void {
        const requestData = this.buildRequestData();

        LoadingStore.loadingSubject.next({
            id: 'postToDamco',
            action: 'show'
        });

        LoadingStore.loadingSubject.next({
            id: 'updateOrderDrop',
            action: 'show'
        });

        // TODO: Understand this
        this.soDrops.find((item) => (item.checked = false));

        // Request body
        const drop_update_body = {
            drop_id: requestData.transfers.orders[0].order.drop_id,
            is_fulfilment_sent: true
        };

        const outbound_update_body = {
            request_identifier: 'shipment_outbound',
            orderdrop_id: requestData.transfers.orders[0].order.drop_id,
            data: {
                incoterm: requestData.transfers.orders[0].shipping.incoterm
            }
        };

        this.getDataService
            .postFulfilment(requestData)
            .subscribe(
                () => {
                    this.getDataService.updateShipmentOutbound(outbound_update_body).subscribe(
                        () => {},
                        (error) => {
                            console.log(error);

                            const errorMsg = 'There was problem updating incoterm in outbound';

                            this.commonDataService._flashMsg(errorMsg, 'error');
                        }
                    );

                    this.getDataService
                        .updateOrderDrop(drop_update_body)
                        .subscribe(
                            () => {},
                            (error) => {
                                console.log(error);

                                const errorMsg =
                                    'Order was successfully communicated but it will still appear on the list,' +
                                    'please contact support (order drop on CORE API not updated)';

                                this.commonDataService._flashMsg(errorMsg, 'error');
                            }
                        )
                        .add(() => {
                            this.getFulfilmentList();

                            LoadingStore.loadingSubject.next({
                                id: 'updateOrderDrop',
                                action: 'hide'
                            });
                        });

                    this.commonDataService._flashMsg(
                        `Fulfilment ${this.fulfilmentType} orders sent with success.`,
                        'success'
                    );
                },
                (error) => this.commonDataService._flashMsg(error, 'error')
            )
            .add(() => {
                LoadingStore.loadingSubject.next({
                    id: 'postToDamco',
                    action: 'hide'
                });
            });
    }

    public updateDropsStatus(selectedDrop, dropLists) {
        this.isDoneValid = false;

        dropLists.map((drop) => {
            drop.checked = false;
            return drop;
        });

        dropLists[selectedDrop].checked = true;

        this.validateDone(dropLists[selectedDrop]);
    }

    public onIncotermAdded(object, so_drop): void {
        let selectedIncoterm;
        this.initNewShippingData(so_drop);

        this.incoterms.map((incoterm) => {
            if (incoterm.id === object.id) {
                selectedIncoterm = incoterm;
            }
        });

        so_drop.new_shipping_data.incoterm = selectedIncoterm.code;
    }

    public onIncotermRemoved(object, so_drop): void {
        so_drop.new_shipping_data.incoterm = '';
    }

    public onCarrierInfoAdded(object, so_drop): void {
        let selectedCarrier;
        this.initNewShippingData(so_drop);

        this.carrierInfo.map((carrier) => {
            if (carrier.id === object.id) {
                selectedCarrier = carrier;
            }
        });
        so_drop.new_shipping_data.carrier = selectedCarrier.carrier;
        so_drop.new_shipping_data.carrier_service_type = selectedCarrier.service_type;

        this.validateDone(so_drop);
    }

    public onCarrierInfoRemoved(object, so_drop): void {
        so_drop.new_shipping_data.carrier = '';
        so_drop.new_shipping_data.carrier_service_type = '';

        this.validateDone(so_drop);
    }

    public onWarehouseChanged(newWarehouse): void {
        this.selectedWarehouse = [newWarehouse];
        this.getFulfilmentList();
        this.getServiceLevelsByWarehouseId();
    }

    public setFilter(type) {
        if (this.communicationType !== type) {
            this.communicationType = type;

            this.getFulfilmentList();
        }
    }
}
