import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fulfilment',
    templateUrl: './fulfilment.component.html',
    styleUrls: ['./fulfilment.component.css']
})
export class FulfilmentComponent implements OnInit {
    filterQuery: any = '';

    constructor() {}

    ngOnInit() {}
}
