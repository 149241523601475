import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { DataTableModule } from 'angular2-datatable';
import { BsModalModule } from 'ng2-bs3-modal';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

// Guards
import { AuthguardGuard } from './guards/authguard.guard';

// Routes
import { EXT3PL_ROUTING } from './app.routes';

// Pipes
import { DataFilterPipe } from './pipes/data-filter.pipe';
import { ProductsFilterPipe } from './pipes/products-filter.pipe';
import { SeasonsFilterPipe } from './pipes/seasons-filter.pipe';
import { SalesOrderFilterPipe } from './pipes/sales-order-filter.pipe';
import { PackFilterPipe } from './pipes/pack-filter.pipe';
import { SeasonModalFilterPipe } from './pipes/season-modal-filter.pipe';
import { FulfilmentEcommerceFilterPipe } from './pipes/fulfilment-ecommerce-filter.pipe';
import { FulfilmentListPipe } from './pipes/fulfilment-list.pipe';
import { KeysPipe } from './pipes/object-keys.pipe';

// Services
import { UserService } from './services/user/user.service';
import { GetdataService } from './services/getdata/getdata.service';
import { CommonDataService } from './services/getdata/common-data.service';
import { ProductsService } from './services/products.service';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/common/header/header.component';
import { HomeComponent } from './components/common/home/home.component';
import { WizardStepComponent } from './components/common/wizard-step/wizard-step.component';
import { WizardComponent } from './components/common/wizard/wizard.component';
import { AddressPanelComponent } from './components/common/address-panel/address-panel.component';

// Fulfilment
import { FulfilmentComponent } from './components/fulfilment/fulfilment/fulfilment.component';
import { FulfilmentInProgressComponent } from './components/fulfilment/fulfilment-in-progress/fulfilment-in-progress.component';
import { FulfilmentDoneComponent } from './components/fulfilment/fulfilment-done/fulfilment-done.component';

// Others
import { LoadingComponent } from './components/loading/loading.component';
import { SelectModule } from './components/common/ng2-select';

@NgModule({
    declarations: [
        // PIPES
        DataFilterPipe,
        ProductsFilterPipe,
        SeasonsFilterPipe,
        SalesOrderFilterPipe,
        PackFilterPipe,
        SeasonModalFilterPipe,
        FulfilmentEcommerceFilterPipe,
        FulfilmentListPipe,
        KeysPipe,

        // COMMON
        AppComponent,
        HeaderComponent,
        HomeComponent,
        AddressPanelComponent,
        WizardComponent,
        WizardStepComponent,

        // FULFILMENT
        FulfilmentComponent,
        FulfilmentInProgressComponent,
        FulfilmentDoneComponent,

        // OTHER
        LoadingComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        SelectModule,
        DataTableModule,
        FormsModule,
        ReactiveFormsModule,
        EXT3PL_ROUTING,
        BsModalModule,
        FlashMessagesModule,
        AngularDualListBoxModule,
        ConfirmationPopoverModule.forRoot({ confirmButtonType: 'danger' }),
        NgxPaginationModule
    ],
    providers: [CommonDataService, GetdataService, ProductsService, UserService, AuthguardGuard],
    bootstrap: [AppComponent]
})
export class AppModule {}
