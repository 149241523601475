import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingStore } from './loading.store';

const loadingActions = {
    show: 'show',
    hide: 'hide'
};

@Component({
    selector: 'app-loading',
    templateUrl: 'loading.component.html',
    styleUrls: ['loading.component.css']
})
export class LoadingComponent implements OnInit {
    /**
     * Guide to use Loading Component:
     *
     * You can set various loading instances at the same time.
     * They will all be identified with an id of you choose.
     * If there are any loading running, the loading will appear on the screen.
     * To make sure the loading does not appear, remove all instances you previously set.
     *
     * To set or remove a loading instance, run:
     * LoadingStore.loadingSubject.next({
     *     id: id,
     *     action: action
     * });
     *
     * action parameter is one of these:
     * 'show', to set a new instance;
     * 'hide', to remove a new instance.
     *
     * id is the identifier for each instance you want to set or to remove.
     * If you set an instance with an id that already exists, nothing will be done.
     * If you remove an instance that does not exist, nothing will be done, as well.
     */

    public showLoading = false;

    constructor() {}

    private toggleShowLoadingStatus(newStatus: boolean) {
        if (this.showLoading !== newStatus) {
            LoadingStore.changeStatusSubject.next(newStatus);
        }
        this.showLoading = newStatus;
    }

    private initializeObservable() {
        if (!LoadingStore.loadingSubject) {
            LoadingStore.loadingSubject = new Subject();
            LoadingStore.changeStatusSubject = new Subject();

            LoadingStore.loadingSubject.asObservable().subscribe((params) => {
                const id = params.id;
                const action = params.action;

                const index = LoadingStore.loadingIds.findIndex((searchingId) => searchingId === id);

                if (index === -1 && action === loadingActions.show) {
                    LoadingStore.loadingIds.push(id);
                } else if (action === loadingActions.hide) {
                    LoadingStore.loadingIds.splice(index, 1);
                }

                const newStatus = LoadingStore.loadingIds.length !== 0;
                this.toggleShowLoadingStatus(newStatus);
            });
        }
    }

    public ngOnInit() {
        this.initializeObservable();
    }
}
