import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'SalesOrderFilter'
})
export class SalesOrderFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { so, drop } = element;

            if (!so && !drop) {
                return false;
            }

            const sales_channel = so && so.sales_channel;
            const customer = so && so.customer;
            const internal_order_num = so && so.internal_order_num;
            const brand = so && so.brand;

            const status = drop.status;
            const orderdrop_date_formated = drop.orderdrop_date_formated;

            const filterList = [sales_channel, customer, internal_order_num, brand, status, orderdrop_date_formated];

            return filterListByQuery(filterList, query);
        });
    }
}
