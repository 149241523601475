import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'FulfilmentList'
})
export class FulfilmentListPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const {
                so_drop_name,
                so_order_num,
                so_huubclient,
                so_customer,
                country,
                drop_status,
                shipping_transfer_status
            } = element;

            const filterList = [
                so_drop_name,
                so_order_num,
                so_huubclient,
                so_customer,
                country,
                drop_status,
                shipping_transfer_status
            ];

            return filterListByQuery(filterList, query);
        });
    }
}
