import { Pipe, PipeTransform } from '@angular/core';
import filterListByQuery from './filter.helper';

@Pipe({
    name: 'FulfilmentEcommerceFilter'
})
export class FulfilmentEcommerceFilterPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (!query) {
            return array;
        }

        return array.filter((element) => {
            const { so, drop, customer } = element;

            if (!so && !drop && !customer) {
                return false;
            }

            const internal_order_num = so && so.internal_order_num;
            const brand = drop && so.brand;
            const status = drop && so.status;
            const name = customer && customer.name;
            const address =
                customer &&
                customer.customer_address &&
                customer.customer_address[0] &&
                customer.customer_address[0].country;

            const filterList = [internal_order_num, brand, status, name, address];

            return filterListByQuery(filterList, query);
        });
    }
}
