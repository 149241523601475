import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductsService {
    private _api_url = environment.GTW_URL + '/api/v1.0/core/masterdata';
    private _external_token = localStorage.getItem('accessToken');

    private options = {
        headers: new HttpHeaders({
            'jwt-access-token': this._external_token
        })
    };

    constructor(private http: HttpClient) {}

    private handleError(response): Observable<any> {
        let error_message = `${response.status} - ${response.statusText}`;

        const body = response.error;

        if (body && body.detail !== undefined) {
            error_message = `${error_message} - ${body.detail}`;
        }

        return observableThrowError(error_message);
    }

    public getBrands(): Observable<any> {
        return this.http.get(this._api_url + '/brands', this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    public getSuppliers(): Observable<any> {
        return this.http.get(this._api_url + '/suppliers', this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    public getSeasons(): Observable<any> {
        return this.http.get(this._api_url + '/product_seasons', this.options).pipe(
            map((response) => response),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }

    public getProducts(filters, page = 1): Observable<any> {
        return this.http.get(this._api_url + `/product_model_search/?page=${page}&${filters}`, this.options).pipe(
            map((res: any) => {
                const products = {
                    data: [],
                    total_count: res.count
                };

                // Add checked option to each product
                res.data.map((elem) => {
                    elem.checked = true;
                    products.data.push(elem);

                    return elem;
                });

                return products;
            }),
            catchError((err: HttpErrorResponse) => this.handleError(err))
        );
    }
}
